import Const from '../constants'
import axiosApiInstance from '../interceptors'

class TransactionInfoService {
  getTransactionInfo() {
    return axiosApiInstance.get(Const.api_url + 'dashboard/transaction-info')
  }
}

export default new TransactionInfoService()
