







































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import TransactionInfoService from '@/services/TransactionInfoService'

@Component({
  components: {
    LayoutCompact
  }
})
export default class TermOfService extends Vue {
  public getTransactionInfo = {
    price_info: '',
    other_price_info: '',
    payment_method_info: '',
    goods_return_info: '',
    expire_date_info: '',
    order_cancel_info: '',
    distributor: '',
    season_info:'',
    phone_no: '',
    manager: ''
  }

  created() {
    this.fetchTransactionInfo()
  }

  private fetchTransactionInfo() {
    TransactionInfoService.getTransactionInfo().then(
      (response: any) => {
        this.getTransactionInfo = response.data.items     
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        this.getTransactionInfo =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      }
    )
  }
}
